.cardConteiner{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.selectSector {
    .form-select {
        width: 100% !important;
        margin-bottom: 20px;
        background-color: #03a1e1 !important;
        color: whitesmoke;
        font-weight: bold;
    }
}