.carousel-client {
    margin-bottom: 15px;

    &-margen {
        padding: .8rem;
        margin-left: 4rem;
        margin-right: 4rem;
    }

    .carousel-caption {
        background-color: rgba(0, 0, 0, 0.528);
    }

    .carousel-control-prev-icon{
        background-color: black !important;
    }

    .carousel-control-next-icon{
        background-color: black !important;

    }


    .image-size {
        display: block;
        max-width: 100%;
        width: auto;
        height: 400px;
        margin: 0 auto;
        background-size: cover !important;

    }

    .carousel .thumb img {
        width: 30px;
        height: 60px;
        background-size: cover !important;
    }

} 