.table-sectors {
    margin-top: .3rem !important;
    font-size: .9rem;
  
    .foto {
        max-width: 250px; 
        height: auto; 
        display: block; 
        margin: 0 auto; 
    }
  
    .action-button {
      margin-left: .5rem;
      font-size: .8rem !important;
    }
  
    .action-button.green:hover {
      -moz-opacity: .50;
      box-shadow: 0 0 5px #21d611, 0 0 40px #047c32, 0 0 80PX #049924;
      opacity: 0.80;
      transform: translate(1px, 1px);
    }
  
    .action-button.red:hover {
      -moz-opacity: .50;
      box-shadow: 0 0 5px #ca0c0c, 0 0 40px #a80606, 0 0 80PX #c50606;
      opacity: 0.80;
      transform: translate(1px, 1px);
    }
  
  }
  
  .btn-boton {
    margin-top: 5px;
    width: 3em;
    height: 3em;
  }
  
  .eliminar-btn {
    font-size: 13.5px;
  }