.add-edit-alianzas-form {
    .add-edit-alianzas-bc {
        margin-top: 1px;
    }

    .add-edit-alianzas-input {
        margin-bottom: 20px;
    }

    .add-edit-alianzas-contenedorFoto {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .foto-tamanio {
        margin-bottom: 10px;
        width: 13vw;
        height: 13vw;
        padding: 1px;
        object-fit: cover;
        background-image: linear-gradient(to left top, #000000, #180410, #210920, #221034, #0d194c);
    }
}

@media only screen and (max-width: 1180px) {
    .foto-tamanio {
        width: 14rem !important;
        height: 14rem !important;
        object-fit: cover;
        background-image: linear-gradient(to left top, #000000, #180410, #210920, #221034, #0d194c);
    }
}

@media only screen and (max-width: 767px) {
    .foto-tamanio {
        width: 14rem !important;
        height: 14rem !important;
        object-fit: cover;
        background-image: linear-gradient(to left top, #000000, #180410, #210920, #221034, #0d194c);
    }
}

@media only screen and (max-width: 570px) {
    .foto-tamanio {
        width: 13rem !important;
        height: 13rem !important;
        object-fit: cover;
        background-image: linear-gradient(to left top, #000000, #180410, #210920, #221034, #0d194c);
    }
}