.datos-compania {
    margin: auto;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);
    padding: 10px;
    transition: transform 0.3s ease-in-out;
    overflow-x: auto;

    span {
        color: #0dabdf;
        font-size: 20px;
    }

    &-child {
        display: inline-block;
        margin-left: .5rem;
    }

    &-parrafo {
        margin-left: 1.5rem;
        margin-top: .3rem;
        margin-bottom: .2rem;
    }

    &:hover {
        transform: scale(1.05);
    }
}