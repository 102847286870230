.cartaPrincipal{
    display: flex;
    align-items: center;
    justify-content: center;

    .imagen{
        height: 20vh; 
        margin: 0px auto;
        display:block;
    }
}

.cartaPrincipal-cotenido {
    .titulo{
        text-align: center;
    }

    .contenedor-carta{
        text-align: center;
    }
}