.categories-container {
  display: flex;
  flex-direction: column;
}

.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.action-buttons {
  display: flex;
}

.save-button {
  background-color: rgb(91, 190, 82);
  margin-left: 5px;
  margin-right: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  border-color: #0f8d48;
  width: 80px;
  height: 40px;
}

.save-button:hover {
  background-color: #06ad51;
}

.categorialist{
  margin-bottom: 25px;
}

.action-buttons button {
  background-color: #007bff;
  color: #fff;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 5px;
}
.action-buttons2 button {
  background-color: #ff0000;
  color: #fff;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 5px;
}


.category-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin-right: 10px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #aeeaff;
   border-radius: 15px;
}


.subcategoryText {
  border-radius: 10px;
  width: 150px;
  height: 40px;
  background-color: #ebfffd;
  border-color: #007bff !important;
  border: 2px solid #ebfffd;
  margin-left: 5px;
  margin-right: 15px;
  margin-bottom: 20px;
  text-align: center;
}



.popover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  z-index: 999;


  .btn-out {
    background-color: #dc3545;
    border-color: #dc3545;
    border-radius: 80px;
    margin-left: 80px;
  }

  .btn-out:hover{
    background-color: #8d0e1b;
  }

  .add-subcategoria-button {
    background-color: #007bff;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    border-color: #007bff;
    padding: 5px 10px;
    margin-right: 5px;
  }
  .add-subcategoria-button:hover{
    background-color: #003f83;
    border-color: #003f83;
  }


  .subcategorias-container {
    margin-top: 10px; // Ajusta según sea necesario
    display: flex;
    flex-direction: column; // Cambia a column para apilar verticalmente
    flex-wrap: nowrap;

  }

  .subcategoria-item {
    background-color: #c9f1ff;
    border: 1px solid #aeeaff;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 15px;
    display: flex;

    button {
      background-color: #dc3545; 
      color: #f0f0f0;
      border: none;
      cursor: pointer;
      padding: 5px 10px;
      margin-right: 5px;
      margin-left: auto;
      border-radius: 10px;
    }
    
  }



  span {
    margin-right: 5px;
  }
}