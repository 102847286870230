.oferta {
  padding: 2em;
  box-shadow: 2px 2px 8px #cacaca;

  .card-planes {
    max-width: 100%;
    margin-bottom: 10px;
    transition: transform 0.2s ease-in-out;
  }

  .card-planes:hover {
    transform: translateY(-5px);
  }

  .card-footer {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
  }


  li {
    margin: 0;
    padding-top: .5rem;
    padding-left: 2rem;
    list-style: none;
    background-image: url("../../../../../images/check-icon.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: .8rem;
    text-align: left;
    font-weight: 700;
    color: #27325f;
  }

  &-color-icon {
    color: #003dd6;
    font-size: larger;
    margin-right: .5rem;
  }

  .card {
    box-shadow: 2px 2px 8px #cacaca;
  }


  .caraltoB {
    height: 50vh !important;
  }

  .caraltoC {
    height: 60vh !important;
  }

  .imagenes {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 20vh !important;
    background-color: #f50b0b;
  }

  &_titleA {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #6385e4;
    padding: 0.5em;
    margin-bottom: 0 !important;
    color: #ffffff;
    font-weight: 900 !important;
    font-size: 2em !important;
  }

  &_sbtitleA {
    color: #ffffff;
    font-size: 2.1em !important;
    font-weight: 800;
    background-color: #6385e4;
    padding: 0.8em;
  }

  &_titleB {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #349ac2;
    padding: 0.5em;
    margin-bottom: 0 !important;
    color: #ffffff;
    font-weight: 900 !important;
    font-size: 2em !important;
  }

  &_sbtitleB {
    color: #ffffff;
    font-size: 2.1em !important;
    font-weight: 800;
    background-color: #349ac2;
    padding: 0.8em;
  }

  &_titleC {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #102f83;
    padding: 0.5em;
    margin-bottom: 0 !important;
    color: #ffffff;
    font-weight: 900 !important;
    font-size: 2em !important;
  }

  &_sbtitleC {
    color: #ffffff;
    font-size: 2.1em !important;
    font-weight: 800;
    background-color: #102f83;
    padding: 0.8em;
  }

  &_titleD {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #ffffff;
    padding: 0.6em;
    margin-bottom: 0 !important;
    color: #eb0808;
    font-size: 3vw !important;
    word-wrap: break-word;
    text-align: center !important;
  }

  @media screen and (max-width: 768px) {
    .oferta_titleD {
      font-size: 8vw !important;
    }
  }

  &_titleD {
    animation-name: animacion;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    overflow: hidden;
  }

  @keyframes animacion {
    0% {
      transform: scale(1);
      color: black;

    }

    50% {
      transform: scale(1.1);
      color: #eb0808;

    }

    100% {
      transform: scale(1);
      color: black;
      ;

    }
  }



  &_titleE {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #ffffff;
    padding: 0.3em;
    margin-bottom: 0 !important;
    color: #020202;
    font-weight: 400 !important;
    font-size: 1.5em !important;
    text-align: justify;
  }

  &_titleF {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #ffffff;
    padding: 0.2em;
    margin-bottom: 0 !important;
    color: #eb0808;
    font-weight: 900 !important;
    font-size: 1.5em !important;
  }

  &_titleG {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #ffffff;
    padding: 0.1em;
    margin-bottom: 0 !important;
    color: #000000;
    font-weight: 900 !important;
    font-size: 2em !important;
  }

  &_botonA {
    box-shadow: 2px 2px 8px #cacaca;
    background-color: #152064 !important;
    border-color: #152064;
    font-weight: 900 !important;
    margin-top: 3.6em;
    margin-bottom: 1em;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
  }

  .oferta_botonA:hover {
    background-color: #020202;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  }

  .oferta_botonB:hover {
    background-color: #020202;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  }

  .oferta_botonC:hover {
    background-color: #020202;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  }

  &_botonB {
    box-shadow: 2px 2px 8px #cacaca;
    background-color: #152064 !important;
    border-color: #152064;
    font-weight: 900 !important;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &_botonC {
    box-shadow: 2px 2px 8px #cacaca;
    background-color: #152064 !important;
    border-color: #152064;
    font-weight: 900 !important;
    margin-top: 1em;
    margin-bottom: 1em;
  }



  &_list {
    .list-group-item {
      border: none !important;
    }
  }
}

.container-cards {
  transition: transform 0.2s ease-in-out;
}

.container-cards:hover {
  transform: translateX(5px) translateY(5px);
}

.animacionSomn_arriba {
  animation: llegada-arriba 1s forwards;
}

@keyframes llegada-arriba {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* 
.animacionSomn_izquierda {
  animation: llegada-izquierda 1s forwards;
}

@keyframes llegada-izquierda{
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.animacionSomn_derecha {
  animation: llegada-derecha 1s forwards;
}

@keyframes llegada-derecha{
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
} */