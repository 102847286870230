.indicator-page {
  &-inline {
    font-family: OpenSansBold;
    margin-left: 0.5rem;
    display: inline-block;
    color: #445890;
  }
  &-iconTit {
    font-size: 27px;
    color: #002575;
  }
  &-iconBody {
    font-size: 65px;
    color: #4682b4;
  }

  .row {
    margin-bottom: 0.5em;
  }

  a {
    font-size: x-small;
    float: right;
  }

  .card-horizontal {
    padding-left: 0.3em;
    display: flex;
    flex-direction: row;
  }

  .card-title {
    font-weight: bold;
  }
  .card {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .card-text {
    font-size: 13px;
    max-height: 100px; /* ajuste la altura máxima según sea necesario */
    overflow: auto;
  }

  .card-footer {
    background-color: #eff5ff;
  }

  .btn.btn-secondary.btn-sm {
    svg {
      margin-right: 0.3rem;
      font-size: medium;
    }
    background-color: #2e406e;
    border-color: #2e406e;
    &:hover {
      background-color: #0dabdf;
    }
  }

  &__divButtond {
    padding-top: 0.5em;
    display: flex;
  }

  &__buttonMas {
    width: 100%;
    background-color: #fff !important;
    border-color: #086685 !important;
    color: #086685 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    &:hover {
      background-color: #086685 !important;
      color: #eee !important;
    }
  }
}

.card-body1 {
  display: flex;
  justify-content: space-between;
}

.animacionSomn {
  animation: llegadaTitulo-Indicadores 1s forwards;
}

@keyframes llegadaTitulo-Indicadores {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.card-hover {
  transition: transform 0.1s ease-in-out;
}

.card-hover:hover {
  box-shadow: 0 0.5em 0.5em -0.4em;
  transform: translateY(-0.25em);
  text-decoration: none;
}

.animacionSom-indicadores {
  animation: llegadaIndicadores 1s forwards;
  opacity: 0;
  transition: transform 0.2s ease-in-out;
}
@keyframes llegadaIndicadores {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
