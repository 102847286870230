.reset-password{

    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    box-shadow: 10px 5px 5px #9B9B9B;
    border: solid 1px #D5D5D5;
    border-radius: 3px;
    padding: 3em;
    width: 450px;

    &_titulo{
        text-align: center;
        margin-bottom: 2em;
        font-weight: 800;
    }

    &_link{
        color: cadetblue;
        float: right;
    }
    .error {
        border: 2px solid #dc3545;
    }
    button{
        margin-top: 2em;
        margin-bottom: 1.5em;
        width: 100%;
    }
    span{
        display: block;
        text-align: center;
        margin-bottom: 2em;
    }

}