.category-container {
  position: relative;
  margin-bottom: 20px;
}

.category-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.btn-categories{
  background-color: #00aeef !important; 
  border-color: #00aeef !important;    
  border-radius: 25px !important;
  float: left;
  color: white;              
  font-size: 16px;
  height: 40px !important;
  width: 200px !important;
  margin-bottom: 0px;
}
.btn-categories:hover {
  border-color: #09a1dc !important; 
  background-color: #0000f5 !important; 
  color: white !important; 
}


.btn-subcategories {
  background-color: #00aeef !important; 
  border-color: #00aeef !important;    
  border-radius: 25px !important;
  color: white;              
  font-size: 16px;
  height: 40px !important;
  width: 150px !important;
}

.btn-subcategories:hover {
  border-color: #09a1dc !important; 
  background-color: #09a1dc !important; 
  color: white !important; 
}

.btn-save{
  background-color: #00aeef !important; 
  border-color: #00aeef !important; 
  margin-left: 10px !important;   
  margin-right: 5px !important;
  border-radius: 25px !important;
  color: white;              
  font-size: 20px;
  height: 40px !important;
  width: 75px !important;
}

.btn-save:hover {
  border-color: #09a1dc !important; 
  background-color: #09a1dc !important; 
  color: white !important; 
}
.btn-cancel{
  background-color: #ff0a14 !important; 
  border-color: #ff0a14 !important; 
  margin-left: 5px !important;   
  border-radius: 25px !important;
  color: white;              
  font-size: 20px;
  height: 40px !important;
  width: 75px !important;
}

.btn-cancel:hover {
  background-color: #ff4800 !important; 
  color: white !important; 
}

.text-category{
  padding: 10px;
  border: 2px solid blue !important; 
    border-radius: 25px !important;
    font-size: 16px !important;
    width: 300px !important;
}