.carta-colaborador {
  display: block;
  margin: auto;
  background-color: #d0dbef !important;
  width: 80rem !important;

  .imagen {
    width: 75px;
    height: 75px;
    backface-visibility: hidden;
  }

  p {
    color: #445890;
    font-family: "OpenSansBold";
    font-size: 13.6px;
    padding-top: 5px;
  }
}
