.top-menu-admin {
  font-family: Geomanist, sans-serif !important;
  font-size: 0.8em !important;
  box-shadow: 0px 1px rgba(118, 118, 118, 0.429);
  margin-bottom: 1em;
  height: 10vh;
  background: #1d3471;
  background: linear-gradient(90deg, #1d3471 0%, #1d3471 40%, #00aeef 80%);

  &__logo {
    margin-left: 0;
    padding: 0;
    top: 0;
    left: 0;
    width: 250px;
    height: 80px;
  }

  .avatar {
    width: 25px;
    height: 25px;
    line-height: 23px;
    border-radius: 50%;
    border: solid 1px;
    border-color: rgb(146, 146, 146);
    font-size: 10px;
    color: #fff;
    text-align: center;
    background: rgb(191, 191, 191);
    margin-right: 0.2em;
  }

  .name-descrip {
    margin-right: 2em;
    font-size: 0.9em;
  }

  .cerrar-sesion {
    margin-left: 0.2em;
    margin-right: 10px;
    font-size: 0.8em;
  }

  .text-white .nav-link {
    color: lightgray;
    font-size: 1.2em;
    font-weight: 600;
  }

  .font-media {
    font-size: 0.9em;
    font-weight: 500;
    color: #383838;
  }
}
