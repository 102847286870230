.login-admin{

  
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    background-image: linear-gradient(325deg, #e6e1d2 25%, #ffffff 40%);

    &__content {
        margin: 0 20px;
        background-color: #fff;
        padding: 50px 50px;
        border-radius: 20px;
        width: 500px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);

        > h1 {
            font-size: 35px;
            text-align: center;
            margin-bottom: 50px;
        }
   }
}