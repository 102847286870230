.row-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .col-label {
    text-align: left;
    flex: 1;
  }

  .row-icono {
    height: 80px;
    width: 80px;
    border-width: 1px;
    border-style: dashed;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 
  .boton-agregar-icono {
    background-color: #023ad3c4 !important;
    border-radius: 10px !important;
    height: 30px;
    width: 150px !important;
    font-size: 12px !important;
    margin-bottom: 20px;
  }

  .column-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .column {
    width: 20%; /* Para dejar un pequeño espacio entre los elementos */
    margin-bottom: 10px; /* Para un espacio entre las filas */
  }
  
  .info-banner-edit {
    height: 300px !important; 
  }
  