.cookie-policy{
    &-link{
        color: rgb(224, 198, 3);

        &:hover{
            color: rgb(140, 117, 25);
        }
    }
    &-input{
        margin-left: 1em;
        .form-check-input{
            width: 1em;
        }
    }
}