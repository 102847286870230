.row-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col-switch {
    text-align: center;
    flex: 1;
  }
  
  .col-label {
    text-align: left !important;
    flex: 1;
  }

  .row-inf {
    background-color: #afe3f0af;
    display: flex;
  }

  .col-banner {
    margin: 20px;
  }

  .input-group {
    margin-left: 20px;
    margin-bottom: 20px;
    width: 500px;
  }

  .input-check {
    height: 38px;
  }

  .checked {
    margin-right: 1px;
  }

  .btn-enviar {
    width: 70px !important;
    font-size: 12px;
    background-color: #51ff00ee;
    border-color: #51ff00ee;
    border-radius: 20px;
  }
  .label-campos{
    padding-left: 50px;
    margin-top: 10px;
  }
  .row-cont {
    height: 450px;
    width: 300px;
    border-width: 5px;
    border-radius: 10px;
    border-style: solid;
    border-color: #51ff00ee;
    background-color: white;
    margin-bottom: 20px;
  }

  .row-img {
    height: 450px;
    width: 300px;
    border-width: 1px;
    border-style: dashed;
    background-color: white;
    margin-bottom: 20px;
  }

  .row-contac {
    height: 100%;
    border-radius: 10px;
    margin: 10px;
  }