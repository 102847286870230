.ayuda{
    &-menu-izq{
        border-right: #dee2e6 solid 1px;
        border-bottom: #dee2e6 solid 1px;
        position: relative;
        width: 100%;
    }
    &-menu-der{
        border-right: #dee2e6 solid 0px;
        border-bottom: #dee2e6 solid 1px;
        position: relative;
        width: 100%;
    }

    &-btn{
        display: block;
        height: 20vh;
        border: 0;
        min-width: 100%;
        border-radius: 0 !important;
    }
    &-text-btn{
        font-size: small;
    }
    &-icon-btn{
        font-size: 1.7rem;
    }

}
