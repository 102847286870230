.add-edit-estudio-form {
    font-size: .9em;

    &__active,
    &__staff {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

    }

    .btn.btn-primary {
        width: 100%;
        margin-top: 10px;
    }

    .error {
        border: 2px solid #dc3545;
    }

    .contenedor{
        height: 350px !important;
    }

    .imagen {
        max-width: 100%;
        border-radius: 16px;
        height: 32vh;
        display:block;
        margin:auto;
    }

    .icon {
        width: 100%;
        font-size: 150px;
    }

    .iconTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 15px;
    }

    .titulo {
        display: flex;
        justify-content: center;
        font-size: 15px;
    }

    .text {
        letter-spacing: 1px;
        font-size: 15px;
        text-align: center;
        display: flex;
    }
}

.modal-header {
    color: darkgray !important;
    text-align: center !important;

    .svg-inline--fa {
        padding-right: 10px;
        color: darkgray !important;
    }
}