.event-page{
    content-visibility: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #445890;

    ul{
        padding-left: 0;
    }

    li{
        margin-top: 10px;
        list-style: none outside;
        &:hover {
            box-shadow: 0 0.5em 0.5em -0.4em;
            transform: translateY(-0.25em);
            text-decoration: none;
        }
    }

    a{
        border-radius: 5px;
        background-color: #eff5ff;
        color: #852b08;
        display: flex;
        align-items: stretch;
        width: 100%;
        text-decoration: none;
        color: inherit;
        &:hover{
            color: inherit;
        }
    }

    .date{
        display: block;
        padding: 20px;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        width: 25%;
        &::after{
            border-radius: 3px;
            content: '';
            width: 5px;
            position: absolute;
            right: 0;
            bottom: 20px;
            top: 20px;
        }
    }

    .color-cranberry.bg-after:after {
        background-color: #086685;
    }

    .day {
        display: block;
        font-size: medium;
        font-weight: 800;
    }
    .month {
        display: block;
        font-size: medium;
        margin-top: 3px;
    }
    .description {
        flex: 1 1 auto;
        padding: 20px;
    }

    .location {
        display: block;
        font-weight: bold;
        display: block;
        margin-top: 3px;
        padding-left: 20px;
        position: relative;
    }

    &__divBtn{
        padding-top: .5em;
        display: flex;
        flex-direction: row-reverse;
    }
    &__btnMas.btn{
        width: 100%;
        font-size: 14px;
        background-color: #fff !important;
        border-color: #086685 !important;
        color: #086685 !important;
        font-weight: bold !important;
        &:hover{
            background-color:  #086685 !important;  ;
            color: #eee !important;
        }
    }

}

.animacionSomn{
    animation: llegadaTitulo-evento 1s forwards;
}

@keyframes llegadaTitulo-evento {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
}



.animacionSom-evento{
    animation: llegadaEvento 1s forwards;
    opacity: 0;
    transition: transform 0.2s ease-in-out;
  }
  @keyframes llegadaEvento {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
  
    to {
        opacity: 1;
        transform: translateX(0%);
    }
  }