.privacidad{
    &-li{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    &-ident1{
        text-indent:10px;
    }
    &-ident2{
        text-indent:20px;
    }
}