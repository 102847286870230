.eventos-list{
    &-img{
        width: 100%;
        height: 25vh !important;
    }

    .tituloDerecha {
        display: flex;
        justify-content: right;
        font-size: 13px;
    }
    
}