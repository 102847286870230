.eventos-tabla{
    margin-top: .3em !important;
    font-size: .9em;
    
}

.btn-boton{
    margin-top: 5px;
    width: 3em;
    height: 3em;
    border-radius: 5px !important;
}

.eliminar-btn{
    font-size: 13.5px;
}