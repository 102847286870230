.login-form-admin{
    .btn.btn-primary{
       width: 100%; 
    }

    .error {
        border: 2px solid #dc3545;
    }

    &__link{
        text-align: right;
        color: rgb(15, 87, 187);
    }
}