.contenedor{
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;

    .imagen{
        width: 100%;
        height: 350px;
    }

    .centrado {
        display: flex;
        justify-content: center;
    }
    
    .derecha {
        display: flex;
        justify-content: right;
    }

    .centradoIcono {
        width: 100%;
        font-size: 35px;
    }
}

