.info-data {
    justify-content: center;
    align-items: center;
  }

  .dev-icon {
    border-left: 2px solid rgb(180, 180, 180);
    border-right: 2px solid rgb(180, 180, 180);

    display: flex;
    justify-content: center;
    align-items: center;
  
    .fa-icon {
      padding: 0.2rem;
      color: green;
    }
  }

  .phone-label {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    font-size: 20px;
  }

  .contacto-label {
    margin-top: 11px;
    margin-left: 5px;
  }
  
  .phone-number {
    font-size: 1;
    margin-left: 0.5rem;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #000000;
  }