.ayuda {
    &-menu-izq {
        border-right: #dee2e6 solid 1px;
        border-bottom: #dee2e6 solid 1px;
        position: relative;
        width: 100%;
    }

    &-menu-der {
        border-right: #dee2e6 solid 0px;
        border-bottom: #dee2e6 solid 1px;
        position: relative;
        width: 100%;
    }

    &-btn {
        display: flex;
        padding: 100rem;
        height: 15vh;
        border: 0;
        min-width: 100%;
        border-radius: 1px !important;
    }

    &-text-btn {
        font-size: 1.5rem;
    }

    &-icon-btn {
        font-size: 2.7rem;
    }

}

.containerBusqueda {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
}

.pading {
    padding-right: 0.5rem;
}