.product {
    text-align: center !important; // Para centrar el contenido
  }
  
  .bar-pro {
    background-color: #dd5858 !important; /* Cambiar el color de fondo a un tono pastel */
    color: white;
    padding: 20px 0;
    margin-bottom: 10px; /* Agregar margen inferior para juntar los elementos */
  }
  
  .bar-pro2 {
    /* Mantener el color de fondo original */
    background-color: #aa4a24 !important; 
    color: white; 
    padding: 20px 0;
  }
  
  .values {
    aspect-ratio: abs($number: 12);
    height: 50px;
    padding: 25px;
    margin-top: 50px;
  }
  
  