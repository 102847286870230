.divider-icon {
  text-align: center;
  margin-top: 2em !important;
  margin-bottom: 1em;
  width: 100%;
  position: relative;

  &_title {
    font-family: OpenSansBold;
    color: #132962;
  }

  &_left {
    background: #c0c0c0;
    width: 40px;
    height: 1px;
    left: 50%;
    margin-left: -52px;
    position: relative;
    float: left;
  }
  &_right {
    background: #c0c0c0;
    width: 40px;
    height: 1px;
    right: 50%;
    margin-right: -52px;
    position: relative;
    float: right;
  }
  &_icon {
    color: #516d89;
    font-size: 13px;
    margin-top: -6px;
    margin-left: -6px;
    position: absolute;
    left: 50%;
  }
}
