.search-basic{

    
    .input-group{
        margin: auto;        
        width: 55% !important;
    }

   &_input{
    margin-right: 0.2em;
    width: 50%;
    
   }
   
   .form-control{
    border-color: #4682B4;
   }

   &_input:focus, &_input:active{
        outline:none;
        border-bottom-color: rgb(50, 152, 240) ;
    } 

    #btn-buscar{
        height: 100%;
    }
}