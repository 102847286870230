.table-recursos-admin{
    margin-top: .3em !important;
    font-size: .9em;

    &__boton{
        margin-left: .5em;
        font-size: .8em !important;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.btn-boton{
    margin-top: 5px;
    width: 3em;
    height: 3em;
}

.eliminar-btn{
    font-size: 13.5px;
}