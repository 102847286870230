.informacion {
  align-items: center;
  text-align: center;
  padding-left: 50vh;
  padding-right: 50vh;
}

.recurso-detalle {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  padding-top: 2rem;

  &-titulo {
    font-family: OpenSansBold;
  }

  &-content {
    font-size: 16px;
    text-align: justify;
  }

  img,
  .video2 {
    height: 30vh;
    width: 100%;
    border-radius: 5px;
    border: rgb(54, 81, 156) solid 3px;
  }

  .format {
    white-space: pre-wrap;
    font-family: OpenSansBold;
  }

  .video {
    margin-left: 40% !important;
    border-radius: 5px;
    border: rgb(54, 81, 156) solid 3px;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .recurso-detalle-boton {
    margin-left: 1px;
  }
}

@media screen and (max-width: 2000px) {
  .informacion {
    align-items: center;
    text-align: center;
    padding-left: 30vh;
    padding-right: 30vh;
  }
}

@media screen and (max-width: 1400px) {
  .informacion {
    align-items: center;
    text-align: center;
    padding-left: 20vh;
    padding-right: 20vh;
  }
}

@media screen and (max-width: 1140px) {
  .informacion {
    align-items: center;
    text-align: center;
    padding-left: 20vh;
    padding-right: 20vh;
  }
}

@media screen and (max-width: 960px) {
  .informacion {
    align-items: center;
    text-align: center;
    padding-left: 15vh;
    padding-right: 15vh;
  }

  .recurso-detalle {
    img {
      height: 25vh;
      width: 100%;
      border-radius: 5px;
      border: rgb(54, 81, 156) solid 3px;
    }
  }
}

@media screen and(max-width: 720px) {
  .informacion {
    align-items: center;
    text-align: center;
    padding-left: 5vh;
    padding-right: 5vh;
  }
  .recurso-detalle {
    img {
      height: 20vh;
      width: 100%;
      border-radius: 5px;
      border: rgb(54, 81, 156) solid 3px;
    }
  }
}

@media screen and(max-width: 540px) {
  .informacion {
    align-items: center;
    text-align: center;
    padding-left: 2rem;
    padding-right: 1vh;
  }

  .recurso-detalle {
    img {
      height: 20vh;
      width: 100%;
      border-radius: 5px;
      border: rgb(54, 81, 156) solid 3px;
    }
  }
}
