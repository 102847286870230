.btn.btn-primary{
    background-color:rgb(13, 171, 223);
    color: #fff;
    border-radius: 3px;
    &:hover{
        background-color: #0061b0 ;
        border-color: #437fb1 ;
    }
}
.btn.btn-success{
    background-color: #8FBC8F;
    border-color: #8FBC8F;
}

.btn.btn-danger{
    background-color: #bf3654;
    border-color: #863648;
    &:hover{
        background-color: #d61e46 ;
        border-color: #863648 ;
    }
}

.btn.btn-secondary{
    background-color: #4682B4;
    border-color: #2c516f;
    &:hover{
        background-color: #0061b0 ;
        border-color: #437fb1 ;
    }
}

.btn.btn-info{
    background-color: #445890;
    border-color: #2f3d61;
    color: whitesmoke;
    &:hover{
        background-color: #18378c ;
        border-color: #2a3c6d ;
    }
}