.line-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: black;
  margin: 0 5px;
}
.linea-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
}

.linea {
  flex-grow: 1;
  height: 1px;
  background-color: black;
  margin: 0 5px;
  position: relative;
}

.linea:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50%;
  transform: translateX(-50%);
}
