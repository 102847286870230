.noticia-detalle {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: OpenSansBold;

  .contenedor-centrado {
    width: 80%; /* Ancho del 80% de la pantalla */
    max-width: 1200px; /* Ancho máximo del contenido */
    margin: 0 auto; /* Centra el contenido horizontalmente */
  }

  .noticia-detalle-img {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .imagen {
    object-fit: cover;
    width: 20vw;
    height: 30vh;
    border-radius: 5px;
    border: rgb(54, 81, 156) solid 3px;
  }

  .noticia-detalle-titulo {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .noticia-detalle-contenido {
    text-align: center;
    margin-bottom: 20px;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    color: #072642;
  }

  .text-color:hover {
    color: #072642;;
  }

  .text-color {
    text-decoration: none;
    color: #195d9a;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }

  .formatoTexto {
     font-size: 1.2rem;
     white-space: 'pre-line';
     line-height: 1.6;
  }
}

.noticia-redaccion {
  white-space: preserve;
}