.conteiner-chat{

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
    margin: 0 auto;
    border: 1px solid #1d3572;
    border-radius: 10px;
    background-color: #e7eaec;
    padding: 20px;

    .respuesta-conteiner {
        flex-grow: 1;
        overflow-y: auto;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
    }

    .message {
        margin: 10px 0;
        padding: 10px;
        border-radius: 10px;
        max-width: 80%;
    }

    .usuario {
        align-self: flex-end;
        background-color: #d1dbe7;
        color: #0f3751;
        padding: 10px;
        margin-bottom: 10px;
    }

    .bot {
        align-self: flex-start;
        background-color: #e2e3e5;
        color: #495057;
        padding: 10px;
        margin-bottom: 10px;
    }
    


}