.formEditCarousel{

    .botonEditarImagen{
        display: grid;
        text-align:center;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .imagen{
        max-width: 100%;
        border-radius: 16px;
    }

    .texto{
        display: grid;
        margin: auto;
        text-align:center;
    }
}