.descripcionWebinar {
  margin-top: 3rem !important;
  margin-bottom: 1.5rem;
  margin-left: 0.5rem;

  .desc-tituloWebinar {
    text-align: left;
    font-family: OpenSansBold;
    color: #172e68;
  }

  .infoWebinar {
    font-size: 19px !important;
    margin-bottom: 0;
    font-weight: 700;
  }
}

@media only screen and (max-width: 570px) {
  .descripcionWebinar {
    margin: 0 auto;

    .desc-tituloWebinar {
      text-align: center;
    }
  }

  .infoWebinar {
    margin-top: 2rem;
    text-align: justify;
  }
}
