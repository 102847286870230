.leftTitle {
    display: flex;
    justify-content: left;
    font-size: 14px;
    text-align: left;
}

.rigthTitle {
    display: flex;
    justify-content: right;
    font-size: 14px;
    text-align: right;
}

.centerTitle {
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-align: center;
}