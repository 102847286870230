.header-client {
  border-bottom: none !important;
  font-family: OpenSansBold;

  .dropdown-item {
    font-size: 0.9em;
  }

  .nav-link {
    margin: 0 2px;
    transition: transform 0.3s ease-in-out;

    &:hover {
      background-color: #033157;
      border-radius: 5%;
      color: aliceblue;
      transform: scale(1.05);
    }

    &.active {
      color: #445890 !important;
      text-decoration-color: rgb(224, 231, 255);
      text-decoration-line: underline;
      text-decoration-thickness: 3px;
    }
  }

  &__in {
    border: solid 1px rgb(198, 198, 198);
    border-radius: 2%;
    width: 100px;

    .svg-inline--fa {
      margin-right: 0.3em;
    }
  }
}

.logo {
  width: 7em;
  height: 4em;
}

.container-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-adm {
  margin-left: 12rem;
}

@media only screen and (max-width: 767px) {
  .header-client {
    margin: 0 20px;
  }

  .header-adm {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7em;
    margin-left: 0;
  }

  .nav-link:hover {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 1080px) {
  .header-adm {
    margin-left: 2rem;
  }
}
