.add-edit-colaborador-form{
    font-size: .9em;

    &__active,
    &__staff{
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

    }
    
    .btn.btn-primary{
        width: 100%;
        margin-top: 10px;
    }

    .error{
        border: 2px solid #dc3545;
    }


    .formatoLabel{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        letter-spacing: 2px;
        font-size: 15px;
    }

    .imagen{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        height: 200px; 
        border-radius: 100px;
        transition: .5s ease;
        backface-visibility: hidden;
    }

    .middle {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%)
    }

    .container {
        position: relative;
        width: 90%;
        text-align: center;
      }
      
    .container:hover .imagen {
      opacity: 0.3;
    }

    .container:hover .middle {
      opacity: 1;
    }

    .formatoLabel-datosUser{
        letter-spacing: 1px;
        font-size: 15px;
        text-indent: 10px;
        margin-bottom: 5px;
    }

    hr {
        border: 0; 
        height: 2px; 
        border-top: 1px dashed black; 
        border-bottom: 1px dashed black;
    }
}

.modal-header {
    color: darkgray !important;
    text-align: center !important;
    
    .svg-inline--fa{
        padding-right: 10px;
        color: darkgray !important;
    }
}
