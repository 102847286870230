.colabora {
  &__tituloPag {
    font-family: OpenSansBold;
    padding-left: 1.2em;
    padding-top: 0.4em;
    background-color: rgba(171, 183, 193, 0.125);
    color: #000;
    width: 100%;
    height: 2.3em;

    &::after {
      height: 1.7em;
      border-right: 7px solid #4682b4;
      content: "";
      left: 0.7em;
      border-radius: 2px;
      //background-color: #FFF;
      //border-radius: 3px;
      //content: '';
      //display: inline;
      //height: 50px;
      //width: 7px;
      position: absolute;
    }
  }

  .card-horizontal {
    display: flex;
    flex: 1 1 auto;
    border-radius: 5px;
    box-shadow: 6px 6px 11px -2px rgba(0, 0, 0, 0.49);
    margin-bottom: 3rem;
  }

  .card-horizontal-end {
    display: flex;
    border-radius: 5px;
    box-shadow: 6px 6px 11px -2px rgba(0, 0, 0, 0.49);
    margin-top: 3rem;
    margin-bottom: 1rem;
    background-color: #061339;

    .content-center {
      margin: 15px 12%;
    }

    .titulo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: OpenSansBold;
      font-size: 32px;
      color: whitesmoke;
    }

    .boton-red {
      background-color: #4682b4;
      border-color: #11457a;
    }

    .marcador {
      text-decoration: underline;
    }
  }

  .content-aling-end {
    margin-left: auto;
  }

  &-card-registro {
    background-color: #eff5ff;
  }

  &-card-contacto {
    background-color: #4682b4;
  }

  &-card-alta {
    background-color: #091c53;
  }

  .card-subtitle {
    font-weight: 900;
    margin-bottom: 0.5rem;
  }

  .letra-azul {
    color: #2e406e;
  }

  &-boton {
    background-color: #2e406e !important;
    border-color: #091c53 !important;
    margin: 10px -5px;
    width: 100%;

    &:hover {
      background-color: #4682b4 !important;
      border-color: #4682b4 !important;
    }
  }

  &__descripcion {
    background-image: url("../../../../images/banner-head.png");
    background-color: #fff;
    background-position: center bottom;
    color: whitesmoke;
    margin-bottom: 4em;
    margin-top: 1.5em;
    padding: 2em;
    border-radius: 7px;
    box-shadow: 2px 2px 8px #cacaca;
    font-size: 14px;
    animation: aparecer 1s ease-in-out forwards;
  }

  @keyframes aparecer {
    0% {
      background-position: center bottom;
    }

    100% {
      background-position: center center;
    }
  }

  &-banner-text {
    text-align: justify;
    font-size: 23px;
  }

  &-banner-tit {
    margin-top: 2rem;
    font-family: OpenSansBold;
    text-align: center;
  }

  &-card-titulo {
    font-family: OpenSansBold;
    font-size: 22px;
    color: whitesmoke;
  }

  &__titulo {
    font-family: OpenSansBold;
    padding-bottom: 0.5em;
  }

  &-cardBen {
    margin-top: 5rem;
    margin-bottom: 4rem;
    color: #333;
    text-align: center;
    max-width: 100%;
  }

  &__iconCardBen {
    font-size: 3em;
    color: #11457a;
  }

  &__text {
    padding-top: 0.5em;
    font-size: 14px;
    font-weight: 600;
  }

  .card {
    height: 100%;
  }

  &__como {
    margin-top: 5rem;
    margin-bottom: 1.5rem;
    padding: 1em;
    //background-color: #fff;
    //border: solid 1px rgb(243, 243, 243);
    width: 100%;
    height: 100%;
    font-size: 14px;
  }

  &__comoTit {
    padding-bottom: 0.3em;

    &::after {
      background-color: #4682b4;
      border-radius: 3px;
      content: "";
      display: block;
      height: 6px;
      width: 3em;
      position: absolute;
    }
  }

  &__paso {
    padding-left: 0.2em;
    text-align: start;
    position: absolute;
    background-image: linear-gradient(to bottom right, transparent 50%, rgb(255, 255, 255) 0);
    border-top-left-radius: 3px;
    float: left;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #2f4f4f;
    width: 50px;
    height: 50px;
    color: #fff;
  }

  &__iconComo {
    font-size: 2em;
    color: #778899;
  }
}

.animacionSomn {
  animation: llegada 1s forwards;
  opacity: 0;
}

/* .animacionSomn-cards {
    animation: llegada-cards 1s forwards !important;
    opacity: 0;
    transition: transform 0.2s ease-in-out !important;
}

.animacionSomn-cards2 {
    animation: llegada-cards2 1s forwards !important;
    opacity: 0;
    transition: transform 0.2s ease-in-out !important;
} */

.animacionSomn-cards:hover,
.animacionSomn-cards2:hover {
  transform: translateY(-5px) translateX(5px) !important;
  background-color: #061339 !important;
  color: #eff5ff !important;
}

@keyframes llegada {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes llegada-cards {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes llegada-cards2 {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

animacionSomn-hover {
  transition: transform 0.5s ease-in-out !important;
}

.animacionSomn-hover:hover {
  transform: translateY(-5px) !important;
  transition-duration: 0.5s !important;
  transition-timing-function: ease-in-out !important;
}
