.indicador-page{
    &-card{
        margin-bottom: 2rem !important;
    }
    
    &-btn{
        margin-left: 1rem ;
    }
}

.card-container{
    margin: 0 20px;
}

.card-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}