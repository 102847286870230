.add-edit-indicadores-form{
    font-size: .9em;

    &__active,
    &__staff{
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

    }

    .btn.btn-primary{
        width: 100%;
    }

    .error{
        border: 2px solid #dc3545;
    }
}

.modal-header {
    color: darkgray !important;
    text-align: center !important;
    
    .svg-inline--fa{
        padding-right: 10px;
        color: darkgray !important;
    }
}
