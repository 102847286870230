.row-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col-switch {
    text-align: center;
    flex: 1;
  }
  
  .col-label {
    text-align: left !important;
    flex: 1;
  }

  .form-select {
    width: 300px !important;
    margin-bottom: 20px;
    background-color: #00aeef !important;
    color: white;
  }

  .text-left {
    text-align: left !important;
  }

  .info-banner {
    background-color: #afe3f0af;
    display: flex;
  }

  .Btn-Color {
    background-color: #004a68;
    border-radius: 10px;
    height: 36px;
    color: #ffffff;
  }

  .data-banner {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .image-banner {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .row-image {
    height: 300px;
    width: 400px;
    border-width: 1px;
    border-style: dashed;
    margin-bottom: 20px;
  }