.alianza {
    &-margen {
        margin-left: 10rem;
        margin-right: 10rem;
    }

    &-desc {
        background-color: rgba(0, 0, 0, 0.712);
        color: aliceblue;
    }

    &-nom {
        background-color: rgba(77, 77, 77);
        color: aliceblue;
    }

    &_text {
        font-size: 15px;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


.slide-container {
    max-width: 70%;
    margin-left: 5rem;
    padding: 40px 0;
}

.slide-content {
    margin: 0 40px;
    overflow: hidden;
    border-radius: 25px;
    box-shadow: 0 20px 20px rgba(29, 2, 2, 0.5);
}

.card {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    border-radius: 25px;

}

.image-content,
.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 14px;
}

.image-content {
    position: relative;
    row-gap: 5px;
    padding: 25px 0;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 50%;
    width: 100%;
    background-color: #022e47;
    border-radius: 25px 25px 0 25px;

}

.overlay::before,
.overlay::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -40px;
    height: 40px;
    width: 40px;
    background-color: #022e47;
}

.overlay::after {
    border-radius: 0 25px 0 0;
    background-color: #FFF;
    border: none;
}

.card-image {
    z-index: 1000;
    position: relative;
    height: 150px;
    width: 150px;
    padding: 3px;
    box-shadow: 0 10px 20px rgba(29, 2, 2, 0.5);
}



.name {
    font-size: 18px;
    font-weight: 500;
    color: rgb(22, 22, 22);
}

.description {
    font-size: 16px;
    color: #2e2d2d;
    text-align: center;
}

.button {
    border: none;
    font-size: 16px;
    color: #FFF;
    padding: 8px 16px;
    background-color: #40b2f4;
    border-radius: 6px;
    margin: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button:hover {
    background: #265DF2;
    color: rgba(255, 255, 255, 1) !important;
    box-shadow: 0 4px 16px rgb(0, 0, 0);
    transition: all 0.2s ease;
}



@media only screen and (max-width: 767px) {
    .alianza {
        &-margen {
            margin-left: 1rem;
            margin-right: 2rem;
        }

    }
    .slide-container {
        width: 350px;
        margin-left: 5rem;
        padding: 40px 0;
    }

    .slide-content {
        margin: 0 40px;

    }


}