.text-resal {
  color: #233b7c;
}

.text-bold {
  font-family: OpenSansBold;
}

.text-extrabold {
  font-family: OpenSansExtraBold;
}

.fs-5-5 {
  font-size: 1.2rem;
}
.text-left {
  text-align: left !important;
}
@media (max-width: 1350px) {
  .split-text-container {
    display: flex;
    flex-wrap: wrap;
  }
  .split-text-container > span {
    width: 50%;
    box-sizing: border-box;
  }
}

.imagen-style {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
  padding: 10px !important;
}

.check-box-style {
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    border: 2px solid darkblue;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    cursor: pointer;
    background-color: white;
    outline: none;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 5px;

    &:checked {
      background-color: white;
      color: darkblue;
    }

    &::before {
      content: "\2713";
      font-size: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      opacity: 0;
    }
  }
}
