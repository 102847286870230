.text-resal {
  color: #233b7c;
}

.text-bold {
  font-family: OpenSansBold;
}

.text-extrabold {
  font-family: OpenSansExtraBold;
}

.fs-5-5 {
  font-size: 1.2rem;
}
