.publicity-page {
  background-color: #000c2d;
  background-image: url("../../../../images/banner-pie.png");
  color: aliceblue;
  padding-top: 1em;
  padding-bottom: 2rem;
  margin-bottom: 2rem;

  &__button {
    margin-top: 1rem;
    padding-bottom: 1.5em;
    text-align: left;
  }

  &-quiero {
    text-align: center;
    font-family: OpenSansBold;
    margin-bottom: 2rem;
  }

  /*   &-quierobtn{
        background-color: #445890 !important;
        font-family: OpenSansBold !important;
        &:hover{
            background-color: #183b9c !important;
        }
    } */

  &-servicio {
    margin-top: 1rem;
    font-family: OpenSansBold;
    font-size: 20px;
  }

  &-lista {
    margin-top: 1rem;
    li {
      font-size: 14px;
      margin-top: 0.5rem;
    }
  }
}

.subscribe-button {
  font-size: 24px;
  font-weight: bold;
  color: white;
  background-color: #2e406e !important;
  border: none;
  padding: 20px 30px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.subscribe-button:hover {
  box-shadow: 6px 6px 11px -2px rgba(0, 0, 0, 0.49);
  transform: translateY(0.2em);
  text-decoration: none;
}

.subscribe-button span {
  position: relative;
  z-index: 2;
}

.subscribe-button .animation-circle {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 100%;
  background-color: rgba(135, 173, 255, 0.164);
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 1.5s linear infinite;
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.subscribe-button:hover .animation-circle {
  animation: ripple 1.5s linear infinite, hover 3s ease-in-out infinite;
}

@keyframes hover {
  0% {
    transform: translateY(0);
  }

  80% {
    transform: translateX(100%);
  }
}

.subscribe-button:active .animation-circle {
  animation: ripple 1.5s linear infinite, press 0.3s ease-out 1;
}

@keyframes press {
  0% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}
