.add-edit-catalogo-form {
    display: flex;
    align-items: center;
    max-width: 400px;
    margin: auto;
    padding: 25px;
    // border: 1px solid #ccc;
    border-radius: 15px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    input {
      flex: 1;
      margin-right: 10px;
      padding: 10px;
      background-color: rgb(191, 255, 255); 
      font-size: 16px;
      border: 1px solid #ddd;
      border-radius: 15px;
      border-color:rgb(0, 57, 214);
    }

    button {
      padding: 10px 15px;
      font-size: 16px;
      background-color:rgb(5, 191, 253);
      color: black;
      border: none;
      border-radius: 15px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    button:hover {
      background-color: rgb(16, 80, 255);
    }
  }
