.add-edit-recurso-form {
    font-size: .9em;

    &__active,
    &__staff {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

    }

    .btn.btn-primary {
        width: 100%;
        margin-top: 10px;
    }

    .error {
        border: 2px solid #dc3545;
    }

    .contenedor{
        height: 50% !important;
        margin-top: 2rem;
    }

    .imagen {
        border-radius: 50%;
        width: 40vh !important;
        height: 25vh !important;
        display:block;
        margin:auto;
    }

    .icon {
        width: 100%;
        height: 10vh;
        font-size: 150px;
    }

    .iconTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 15px;
    }

    .titulo {
        display: flex;
        justify-content: center;
        font-size: 15px;
        text-align: center;
    }

    .text {
        letter-spacing: 1px;
        font-size: 15px;
        text-align: center;
    }
}

.modal-header {
    color: darkgray !important;
    text-align: center !important;

    .svg-inline--fa {
        padding-right: 10px;
        color: darkgray !important;
    }
}

@media only screen and (max-width: 570px) {
    .imagen {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display:block;
        margin:auto;
    }
}