.table-CRM {
  margin-top: 0.3rem !important;
  font-size: 0.9rem;
}

/*Diseño del Titulo*/
.titulo_CRM {
  padding-top: 0.5rem;
  text-align: left;
  font-family: OpenSansBold;
  color: #172e68;
}

.table-crm-admin {
  margin-left: 0.2rem;
  font-size: 0.8rem !important;
}

.table-crm-admin.boton:hover {
  -moz-opacity: 0.5;
  opacity: 0.8;
  transform: translate(1px, 1px);
}

.statusCli {
  .svg-inline--fa.fa-check {
    color: #8fbc8f;
  }

  .svg-inline--fa.fa-xmark {
    color: #b22222;
  }
}

.btn-boton {
  margin-top: 5px;
  width: 3em;
  height: 3em;
}

.eliminar-btn {
  font-size: 13.5px;
}
