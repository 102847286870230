.sec-page{
   font-family: OpenSansBold;
   min-height: 100vh;
   h3{
    margin-left: 25%;
    
   }
    &-card{
        margin-bottom: 1rem !important;
    }

    
    &-btn{
        margin-left: 1rem ;
    }
    
}


.card-container{
    margin: 0 20px;
}

.cb{
    color: #195d9a;
}

.text{
    text-decoration: none;
    color: black;
    text-align: justify;
}
.text:hover{
    text-decoration: none;
    color: black;
    text-align: justify;
}
.texto-recortado {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .sec-p {
    font-family: OpenSansBold;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Centra verticalmente el contenido */
    min-height: 100vh; /* Hace que el componente ocupe el 100% del alto de la pantalla */
    margin-left: 3rem;
  
    .noticias-container {
      flex-grow: 1; /* Hace que este contenedor ocupe todo el espacio disponible */
      display: flex;
      justify-content: center; /* Centra el contenido horizontalmente */
      align-items: center; /* Centra el contenido verticalmente */
    }
  }