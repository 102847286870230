.licenciamiento {
  &-menu-izq {
    border-right: #dee2e6 solid 1px;
    border-bottom: #dee2e6 solid 1px;
    position: relative;
    width: 100%;
  }

  &-menu-der {
    border-right: #dee2e6 solid 0px;
    border-bottom: #dee2e6 solid 1px;
    position: relative;
    width: 100%;
  }

  &-btn {
    display: flex;
    padding: 100rem;
    height: 100%;
    border: 0;
    min-width: 100%;
    border-radius: 1px !important;
  }

  &-text-btn {
    font-size: 1.2rem;
  }

  &-icon-btn {
    font-size: 2.7rem;
  }
}

.contenedor {
  display: grid;
  place-items: center;

  .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }

  .acordeon {
    padding-top: 30px;
    width: 70% !important;
  }

  .text {
    font-size: 16px;
    font-family: OpenSansBold;
    text-align: justify;
  }
}
