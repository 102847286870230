.catalogos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 200px !important;
  padding: 50px;
  
}

.catalogos-title {
  margin-bottom: 25px; 
}

.catalogos-container > div {
  margin-bottom: 100px; 
}

.catalogos-icon{
margin-right: 15px;
margin-left: 20px;
}