.table-indicadores-admin{
    margin-top: .3em !important;
    font-size: .9em;

    &__boton{
        margin-left: .5em;
        font-size: .8em !important;
    }

    .status{
        .svg-inline--fa.fa-check {
            color: #8FBC8F;
        }
        .svg-inline--fa.fa-xmark {
            color: #B22222;
        }

    }

}

.btn-boton{
    margin-top: 5px;
    width: 3em;
    height: 3em;
}

.eliminar-btn{
    font-size: 13.5px;
}