.opiniones{
    &-form{
        padding: 3em;
        box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);
        //box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }
}

.add-edit-experiencia-form {
    .add-edit-experiencia-bc {
        margin-top: 1px;
    }

    .add-edit-experiencia-input {
        margin-bottom: 20px;
    }
}
.add-edit-experiencia-contenedorFoto {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.foto-tamanio {
    margin-bottom: 10px;
    width: 13vw;
    height: 13vw;
    padding: 1px;
    object-fit: cover;
    background-image: linear-gradient(to left top, #000000, #180410, #210920, #221034, #0d194c);
}