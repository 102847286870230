.btnDocumento {
    margin-top: 5px;
}

.btnPublicarArchivo {
    margin: 10px;
}
.container-btnPublicarArchivo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-hover:hover {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-hover:focus {
    box-shadow: none;
  }
  
  .btn-hover:active {
    background-color: #0062cc;
    border-color: #005cbf;
  }
  
  .btn-hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0069d9;
  }
  
  .btn-hover:hover, .btn-hover:focus, .btn-hover:active {
    color: #fff;
  }
  