// DeleteCatalogoForm.scss
// h2{
// font-size: 1.5em;
// margin-bottom: 30px;
// }
.Eliminar {
    background-color: green;
    color: white;
    margin-left: 20px;
    margin-right: 15px;
    border-radius: 15px;
    padding: 10px;
    margin-top: 25px;
    border-color: green !important;
    border-bottom: green;
  }
  .Eliminar:hover{
    background-color: darkgreen !important;
    border-color:darkgreen;
  }

  .Cancelar {
    background-color: red;
    color: white;
    margin-right: 5px;
    margin-left: 25px;
    border-radius: 15px;
    margin-top: 25px;
    padding: 10px;
    border-bottom: red;
    border-color: red;
  }
  .Cancelar:hover{
    background-color: darkred;
    border-color: darkred;
  }
