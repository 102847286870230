.client-layout{
    input{
        width: 40%;
    }
}

.back-layout{
    position: absolute;
    margin-top: 20px;
    width: 100%;
    height: 100vh;
    transform: skewY(-10deg);
    z-index: -1;
    overflow: hidden;
    background: #f5f7f9;
}

.footer-position{
    bottom: 0;
    width: 100%;
}

.social-bar {
    position: fixed;
    right: 0;
    top: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100;

    .icon-facebook,.icon-twitter, 
    .icon-youtube, .icon-instagram, .icon-whats  {
        width: 1.5em;
        color: #b7ccff;
        text-decoration: none !important;
        font-size: 1.5rem !important;
        display: flex;
        padding-right: 0.3em;
        padding-left: 0.3em;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        transition: all .5s;
    }
    
    .icon-facebook {
        background: #2E406E;
        border-radius: 1rem 0 0 0;
    }
    
    .icon-twitter {
        background: #2E406E;
    }
    
    .icon-youtube {
        background: #2E406E;
        border-radius: 0 0 0 1rem;
    }
    
    .icon-instagram {
        background: #2E406E;
    }

    .icon-whats{
        background-color: #2E406E;
    }

    .icon-facebook:hover, .icon-twitter:hover,
    .icon-youtube:hover , .icon-instagram:hover, .icon-whats:hover {
        padding-right: 4rem;
        border-radius: 1rem 0 0 1rem;
        box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.42);
    }
}




