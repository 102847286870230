.wizard {
  .tit-tema {
    width: 40%;
    font-weight: 800;
  }

  .square {
    width: 3vw;
    height: 3vw;
  }

  .tema-1-c1 {
    background-color: #8ec93d;
  }
  .tema-1-c2 {
    background-color: #a4a0a0;
  }
  .tema-1-c3 {
    background-color: #000000;
  }
  .tema-2-c1 {
    background-color: #ff0000;
  }
  .tema-2-c2 {
    background-color: #970606;
  }
  .tema-2-c3 {
    background-color: #8e2142;
  }
  .tema-3-c1 {
    background-color: #00aeef;
  }
  .tema-3-c2 {
    background-color: #1d3471;
  }
  .tema-3-c3 {
    background-color: #63798e;
  }
  .tema-4-c1 {
    background-color: #6222cc;
  }
  .tema-4-c2 {
    background-color: #ff7700;
  }
  .tema-4-c3 {
    background-color: #000000;
  }
  .tema-5-c1 {
    background-color: #a90d0d;
  }
  .tema-5-c2 {
    background-color: #000000;
  }
  .tema-5-c3 {
    background-color: #909090;
  }
  .tema-6-c1 {
    background-color: #6e85b2;
  }
  .tema-6-c2 {
    background-color: #d5d5d5;
  }
  .tema-6-c3 {
    background-color: #261c2c;
  }
}

