.table-servicio-talento-especializado{
    margin-top: .3em !important;
    font-size: .9em;

    &__boton{
        margin-left: .5em;
        font-size: .8em !important;
    }

    .status{
        .svg-inline--fa.fa-check {
            color: #8FBC8F;
        }
        .svg-inline--fa.fa-xmark {
            color: #B22222;
        }

    }

}