.equipo-page {
  img {
    border-radius: 50%;
    padding: 2em;
    height: 300px;
    width: 13em;
  }

  &_title {
    text-align: center;
    font-family: OpenSansBold;
    font-size: medium !important;
  }

  .carousel-item {
    text-align: center;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: rgb(120, 120, 120);
  }

  .carousel-caption {
    color: black;
    position: unset;
  }

  .carousel-img {
    max-width: 25vw;  
    object-fit: contain;
  }

  .equipo-titulo {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .equipo-nombre {
    color: #132962;
    font-weight: 800;
  }

  .equipo-puesto {
    color: #bf3654;
    font-weight: 600;
  }

  .equipo-caracteristica {
    font-weight: 600;
  }
}

.col-sm-9 {
  flex-basis: 70%;
  max-width: 70%;
}

.col-md-6 {
  flex-basis: 50%;
  max-width: 50%;
}

@media screen and (max-width: 768px) {
  .justify-content-md-center {
    justify-content: center;
  }
}
