.container_Factura {
    margin-bottom: 2em;
}

.questions_title_Factura {
    font-size: 25px;
}

.description_Factura {
    text-align: justify;
    font-size: 15px;
}