.contenedorCard {
    padding-left: 45px;

    .imagen {
        width: 18rem;
        height: 13rem !important;
    }
}

#btn-mas{
    background-color: #4682B4;
}

.contenedor{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}