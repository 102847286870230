.formatoTexto{
    font-size: smaller;
    
}

.icon_webinar {
    width: 100%;
    height: 10vh;
    font-size: 150px;
}

.text {
    text-align: center;
}