.row-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col-switch {
    text-align: center;
    flex: 1;
  }
  
  .col-label {
    text-align: left !important;
    flex: 1;
  }

  .gal-inf {
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
  }
.titulo-seccion {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.imagen-recuadro {
  height: 200px;
  width: 98%;
  border-width: 1px;
  border-style: dashed;
  margin-bottom: 20px;
}

.Agregar-imagen {
  flex-direction: row;
}

.imagen {
  align-items: center;
  justify-content: center;
}

.esc-imagen{
  width: 140px;
}

.Agrega-Mas {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boton-agregar-mas {
  width: 80% !important;
  background-color: #002a9c !important;
  border-color: #002a9c;
  border-radius: 15px !important;
}