.delete-user-form{
    text-align: right;
}

.title-spacing{
    margin-bottom: 30px;
}

.btnaling{
    margin-right: 10px !important;
}