.footer-page {
  margin-top: 1em;
  padding-top: 20px;
  padding-bottom: 40px;
  background: #000c2d;
  color: #bcceff;
  overflow: hidden;

  &__col {
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 0 20px 20px;
  }

  &__ul {
    padding-left: 15px;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
    list-style: none outside;
    margin: 0;
  }

  &-item-node {
    flex: auto;
    padding: 0;
    position: relative;

    &::after {
      background-color: #324c92;
      border-radius: 3px;
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      bottom: 0;
      width: 5px;
    }
  }

  &__link {
    display: flex;
    justify-content: center;

    img {
      margin-left: 2em;
      width: 7em;
      height: 4em;
      padding: auto;
    }
  }

  .item {
    padding: 0;
  }

  a {
    text-decoration: none;
    color: #8da4dc;

    &:hover {
      color: rgb(255, 255, 255);
    }
  }

  @media screen and (max-width: 768px) {
    .footer-page__col {
      flex-flow: column;
      margin-bottom: 20px;
      justify-content: center;
      align-items: center;
    }
    .footer-page-item-node::after {
      background-color: #324c92;
      border-radius: 3px;
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      bottom: 0;
      height: 5px;
    }

    .footer-page__ul {
    
      font-size: 15px;
      margin-bottom: 15px;
      margin-top: 5px;
    }
    .ots{
        margin-left: 55px;
    }
    .item {
      font-size: 13px;
    }
    
  }
}
