.contenedor_contactanos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.datosCompania {
    margin-bottom: 20px;
    padding: 10px;
    animation: llegada 1s forwards;
    opacity: 0;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
}

.datosCompania:hover{
    transform: scale(1.05);

}


.datosForm {
    padding: 30px;
    margin-right: 100px;
    margin-bottom: 20px;
    animation: llegada2 1s forwards;
    opacity: 0;

}

@keyframes llegada {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
}


@keyframes llegada2 {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
}


@media only screen and (max-width: 767px) {
    .contenedor_contactanos {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .datosCompania {
        margin-bottom: 20px;
    }

    .datosForm {
        margin-right: 0px;
        margin-bottom: 20px;

    }
   
}