.container_Servicio {
    margin-bottom: 2em;
}

.questions_title_Servicio {
    font-size: 25px;
}

.description_Servicio {
    text-align: justify;
    font-size: 15px;
}