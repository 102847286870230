.extra-layout{
    height:  10vh;
    background-color: rgba(154, 154, 154, 0.2);

    .logo{
        padding-top: 1em;
        padding-left: 1em;
        width: 11em;
        height: 7em;
        margin-top: -15px;
      }
}