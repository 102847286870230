.sector-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
  margin-left: 30px;
}
.sector-page {

    &:hover {
      box-shadow: 0 0.5em 0.5em -0.4em;
      transform: translateY(-0.25em);
      text-decoration: none;
    }
    &-inline {
      font-family: OpenSansBold;
      margin-left: 0.5rem;
      display: inline-block;
      color: #445890;
    }
  
    &-iconTit {
      font-size: 25px;
      color: #002575;
    }
  
    .fondo {
      position: absolute;
      width: 100%;
      height: 11%;
      top: 95%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.7) 40%, rgba(0, 0, 0, 1) 100%);
    }
  
    .bottom-right {
      font-size: 12px;
      position: absolute;
      bottom: 8px;
      right: 16px;
    }
  
    .bottom-left {
      font-size: 12px;
      position: absolute;
      bottom: 8px;
      left: 16px;
    }
  
    .centered {
      width: 100%;
      font-size: 15px;
      position: absolute;
      top: 80%;
      left: 50%;
      text-align: left;
      margin-left: 10px;
      margin-top: 50px;
      transform: translate(-50%, -50%);
    }
  
    &-container {
      font-family: "Roboto", sans-serif;
      position: relative;
      text-align: center;
      color: white;
      transition: transform 0.1s ease-in-out;
  
      &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em;
        transform: translateY(-0.25em);
        text-decoration: none;
      }
    }
  
    &-inline {
      font-family: OpenSansBold;
      margin-left: 0.5rem;
      display: inline-block;
    }
  
    &-square {
      margin-right: 0.4rem;
      padding: 0.1em;
      color: #cccccc;
      border-radius: 1px;
      box-shadow: 0 0 0 3px;
    }
  
    &-text {
      margin-top: 0.5rem !important;
    }
  
    &-image {
      border-radius: 5px;
      width: 100%;
      height: 34vh;
    }
  
    &-card {
      padding: 0.5rem;
      width: 30%;
      display: inline-block;
    }
  }
  
  .animacionSomn {
    animation: llegada-cards 1s forwards;
  }
  
  @keyframes llegada-cards {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
  
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
  
  .animacionSom-card {
    animation: llegadaNoticias 1s forwards;
    opacity: 0;
    transition: transform 0.2s ease-in-out;
  }
  @keyframes llegadaNoticias {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
  
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
  
