.mision-page {
  font-family: Helvetica, Arial, sans-serif;

  .img-mision {
    width: 100%;
    height: 9rem;
  }

  &-subtitulo {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 28px;
    color: #132962;
  }

  &-subtitulo2 {
    display: flex;
    justify-content: flex-start;
    font-weight: 700;
    font-size: 28px;
    color: #132962;
  }

  .mision-page-subtitulo {
    animation: llegada 1s forwards;
    opacity: 0;
  }

  .mision-page-subtitulo2 {
    animation: llegada2 1s forwards;
    opacity: 0;
  }

  .mision-page-subtitulo:hover,
  .mision-page-subtitulo2:hover {
    color: #3e538a;
  }

  .animacionSomn_arriba {
    animation: llegada-arriba 1s forwards;
    opacity: 0;
  }

  @keyframes llegada {
    from {
      opacity: 0;
      transform: translateX(100%);
    }

    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes llegada2 {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes llegada-arriba {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  &_parrafo {
    margin-bottom: 2em;
  }

  &_p {
    margin: 0;
    font-size: 15px;
    text-align: right;
  }

  &_pizq {
    margin: 0;
    font-size: 15px;
    text-align: left;
  }

  &-cont {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
    background-image: url("../../../../images/banner-head.png") !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 4rem;
    animation: aparecer 1.5s ease-in-out forwards !important;
  }

  @keyframes aparecer {
    0% {
      background-position: center bottom;
    }

    100% {
      background-position: center center;
    }
  }

  &-card {
    margin-bottom: 3rem;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: whitesmoke;
    min-height: 45vh;

    width: 100%;
    height: 100%;
    background-size: 100% 200%;
    transition: transform 0.2s ease-in-out;

    &:hover {
      .mision-page-cardText {
        color: whitesmoke;
      }
      transform: translateY(-5px);
      background-color: #00071c;
    }
  }

  &-icon {
    width: 30%;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  &-cardTit {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 15px;
    font-weight: 800;
  }

  &-cardText {
    text-align: justify;
    font-size: 12px;
    color: transparent;
  }

  &_card {
    margin: 1em;
    min-height: 300px;
    border: 1px solid rgb(201, 201, 201);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

    .card-title {
      text-align: center;
      margin-top: 1.4em;
      margin-bottom: 1em;
    }

    svg {
      padding: 0.3em;
      background-color: #bf3654;
      border-radius: 50%;
      display: inline-block;
    }
  }

  &_icon {
    font-size: 1.9em;
    color: aliceblue;
  }

  &_sbtitle {
    font-family: OpenSansBold;
    text-align: center;
    margin-bottom: 1em !important;
  }

  &_txt {
    text-align: justify;
  }
}

@media screen and (max-width: 575px) {
  .justify-content-center {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }

  .mision-page_p {
    text-align: justify;
  }

  .mision-page-subtitulo,
  .mision-page-subtitulo2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mision-item {
    margin: 5px 10px;
    padding: 0 20px;
    text-align: center;
  }
}
