.detalle-recurso {
  padding: 1em;
  margin: 1rem;

  &-imagen {
    border-radius: 5px;
    box-shadow: 6px 6px 11px -2px rgba(0, 0, 0, 0.49);
    padding: 1rem;
    background-color: #f4f8ff;
    align-items: center;
  }
  .lista {
    margin-top: 1rem;
    font-weight: bold;
  }

  .img-thumbnail {
    width: 24rem !important;
    height: 12.5rem !important;
  }

  .img-square-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .color-titulo {
    font-family: OpenSansBold;
    color: #445890;
  }
  .textoCentrado {
    text-align: center;
  }

  .botonInformacion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bottonInfo {
    width: 15rem;
    height: 3rem;
  }
}

.contenedor {
  display: grid;
  place-items: center;
}

@media only screen and (max-width: 570px) {
  .img-thumbnail {
    width: 20rem !important;
    height: 11rem !important;
  }

  .img-square-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .botonInformacion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bottonInfo {
    width: 15rem;
    height: 3rem;
  }
}
