.container0 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    h1 {
        color: #749AD6;
    }

    h4 {
        color: #7294b9;
    }
}

.container1,
.container2 {
    margin-top: 30vh;
}

.container1 {

    width: 20rem;
}

@media only screen and (max-width: 874px) {

    .container1,
    .container2 {
        margin-top: 10vh;
    }
}
@media only screen and (max-width: 480px) {
    .image {
        width: 90%;
    }
}

.colorTitulos{
    color: #6d90c7;
}

.colorSubTitulo{
    color: #3a4e6e;
}

.conteinerB2B{
    height: 100%;
    padding: 50px !important;
    margin: 0 auto;
}

.iconB2B{
    height: 250px;
    color: #1d3471
}