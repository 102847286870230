.nav-pills .nav-link{
  border-radius: 5 !important;
  color: #ffffff !important;
  background-color: #d4d4d4 !important;
}

.nav-pills .nav-item:not(:nth-last-child(1)) .nav-link{
  border-right: 1px solid rgb(255, 255, 255);
}

.tab-content{
  background-color: #f9fafb;
}

/* Estilo para el tab seleccionado */
.nav-pills .nav-link.active,
.nav-pills .nav-link:focus {
  background-color: rgb(13, 171, 223) !important;
}