.busquedaTitulo {
  margin-top: 100px;
}

.busquedaLore {
  margin-top: 100px;
  margin-bottom: 200px;
}

.busqueda {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  &-banner-tit {
    margin-top: 2rem;
    font-family: OpenSansBold;
    text-align: center;
  }
  .animacionSomn {
    animation: llegada 1s forwards;
    opacity: 0;
  }

  animacionSomn-hover {
    transition: transform 0.5s ease-in-out !important;
  }

  .animacionSomn-hover:hover {
    transform: translateY(-5px) !important;
    transition-duration: 0.5s !important;
    transition-timing-function: ease-in-out !important;
  }
}

.buscador {
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
}

.search {
  width: 100rem;
  height: 50px;
  border-radius: 120px;
  margin: 0 auto;
  font-size: 20px;
  border-color: rgb(71, 89, 168);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 0 20px;
}

.search:hover {
  background-color: rgba(109, 109, 109, 0.144);
}

.iconBoton {
  color: black;
}

.botonBuscar {
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 120px;
  border-color: rgb(71, 89, 168);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-left: 10px;
}

.botonBuscar:hover {
  background-color: rgba(0, 0, 0, 0.904);
  .iconBoton {
    color: white;
  }
}

@media only screen and (max-width: 570px) {
  .buscador {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .botonBuscar {
    margin: 10px 0;
  }
  .search {
    width: 20rem !important;
  }
}
