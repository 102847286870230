.content{
    .btn-sm{
        height: 18px;
        width: 14px;
        margin-left: 90%;
        
        p{
            font-size: 10px;
            margin-top: -3px;
            margin-left: -3px;
        }
    }
}

@media (max-width:615px){

    .content{
        .btn-sm{
            
            p{
                font-size: 10px;
                margin-top: -4px;
                margin-left: -4px;
            }
        }
    }
}

@media (max-width:467px){

    .content{
        
        .btn-sm{
            
            p{
              
                font-size: 10px;
                margin-top: -3px;
                margin-left: -3px;
            }
        }
    }
}

@media (max-height:860px){
    .content{

        .btn-sm{
            
            p{
                 
                font-size: 10px !important;  
                margin-top: -3px;
                margin-left: -3px;
            }
        }
    }
}