.titulo-principal {
  font-family: OpenSansBold;

  background-image: url("../../../images/fondo-header.png");
  animation: aparecer 0.3s ease-in-out forwards;

  &__header {
    color: whitesmoke;
    padding-top: 0.3em;
    padding-left: 1.2em;
    background-color: #abb7c120;
    width: 100%;
    height: 2.3em;

    &::after {
      height: 1.7em;
      border-right: 7px solid #fefeff;
      content: "";
      left: 0.7em;
      border-radius: 2px;
      position: absolute;
    }
  }
}

@keyframes aparecer {
  0% {
    background-position: center bottom;
  }

  100% {
    background-position: center center;
  }
}
