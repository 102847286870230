.cancel{
    &-header{
        height: 15vh;
        background-image: url("../../../../images/fondo-header.png");
        color: aliceblue !important;
    }
    &-icon{
        font-size: 4rem;
        color: rgb(172, 57, 57);
    }
}