.left{
    position: absolute !important;
    right: 8% !important;
    bottom: 70%;
    
}

.rigth{
    position: absolute !important;
    bottom: 41%;
    .card{
        width: 16rem !important;
    }
}

.map{
    svg{
        margin-top: 20px;
        margin-left: -50px;
        width: 850px;
    }
}

@media (max-width:1600px) and (max-heigth:979px){
    .left{
        position: absolute !important;
        right: 2% !important;
        bottom: 70%;
        
    }
    
    .rigth{
        position: absolute !important;
        bottom: 40%;
        .card{
            width: 16rem !important;
        }
    }
}

@media(max-height:1299px){
    .left{
        top:45% !important;
    }
    .rigth{
        top: 70% !important;
    }
}

@media(max-height:980px){
    .left{
        
        top:none;
        top: 15% !important;
        right: 4% !important;
    }
    .rigth{
        top: 40% !important;
    }
}

@media(max-height:850px){
    .left{
        
        top:70px !important 
    }

    .rigth{
        top: 320px !important;
    }
}



@media (max-width:1570px){

    .left{
        position: absolute !important;
        right: 4% !important;
        bottom: 70%;
        
    }

    .map{
        svg{
            width: 750px;
        }
    }
}
@media (max-width:1500px){
    .left{
        right: 2% !important;
        bottom: 70%;
        .card{
            width: 15rem !important;
        }
    }

    .rigth{
        bottom: 45%;
        .card{
            width: 15rem !important;
        }
    }
    .map{

        svg{
            width: 740px;
        }
        
    }
}


    @media (max-width:1470px){
    .left{
        right: 2% !important;
        bottom: 70%;
        .card{
            width: 13rem !important;
        }
    }

    .map{

        svg{
            margin-left: -50px;
            width: 750px;
        }
        
    }
}



@media (max-width:1400px) {
    .left{
        
       right: 3% !important; 
       height: 10px !important;
       top:10%;
       .card{
        width: 13rem !important;
        height: 11rem !important;
        .card-title {
            font-size: 20px;
        }
        .card-subtitle{
            font-size: 15px;
        }
        p{
            font-size: 12px;
        }
       }
    }

    .rigth{
        bottom: 40%;
        .card{
            width: 16rem !important;
        }
    }

    .map{

        svg{
            margin-left: -100px;
            width: 640px;
        }
        
    }
}

@media(max-height:850px) and (max-width:1400px) {
    
    .rigth{ 
        
        top: 350px !important;
        .card{
            width: 14rem !important;
            height: 13rem !important;
            .card-title {
                font-size: 17px;
            }
            .card-subtitle{
                font-size: 15px;
            }
            p{
                font-size: 15px;
            }
        }
    }
}

@media (max-width:1320px){
    
    .left{
        right: 2%;
        bottom: 90%;

        .card{
            width: 11rem !important;
            height: 11rem !important;
            .card-title {
                font-size: 20px;
            }
            .card-subtitle{
                font-size: 15px;
            }
            p{
                font-size: 12px;
            }
        }
    }

    .map{

        svg{
            margin-left: -120px;
            width: 630px;
        }
        
    }
}

@media (max-height:850px) and (max-width:1320px){
    .left{
        
        top: 70px;
    }
    .map{

        svg{
            margin-left: -50px;
            width: 630px;
        }
        
    }
}

@media (max-height:620px) and (max-width:1320px){
    .left{
        bottom: 60%;
    }
}

@media (max-height:500px) and (max-width:1320px){
    .left{
        top: 50px;
    }
    .rigth{
        top:280px;
    }
}

@media (max-width:1198px) {
    .left{
       right: 10% !important;
       height: 10px !important; 
       top:55% !important;
       

       .card{
        width: 15rem !important;
        height: 13rem !important;
        .card-title {
            font-size: 25px;
        }
        .card-subtitle{
            font-size: 15px;
        }
        p{
            font-size: 15px;
        }
       }
    }

    .rigth{
        top:80% !important;
        .card{
            width: 17rem !important;
        }
    }

    .map{

        svg{
            margin-left: auto   ;
            width: 800px;
        }
        
    }
}

@media(max-height:890px) and (max-width:1198px){
    .left{
        top: 530px !important;
    }
    .rigth{
        top:800px !important;
    }
   
}

    // @media(max-height:850px) and (max-width:1198px){
    //     .left{
    //         bottom: 35%;
    //     }
    //     .rigth{
    //        top: 100%;
    //        .card{
    //         width: 13rem !important;
    //         height: 13rem !important;
    //         .card-title {
    //             font-size: 25px;
    //         }
    //         .card-subtitle{
    //             font-size: 15px;
    //         }
    //         p{
    //             font-size: 12px;
    //         }
    //        }
    //     }
            
    // }

    // @media(max-height:770px) and (max-width:1198px){
    //     .left{
    //         top: 650px;
    //     }
    //     .rigth{
    //         top:700px !important;
    //     }
    // }


@media (max-width:1110px){
    .left{
        top: 15% !important;
        right: 6% !important;
        .card{
            width: 14rem !important;
        }
    } 
    .map{

        svg{
            
            width: 900px;
        }
        
    }

    .rigth{
        top:85% !important
    }
}

@media(max-height:921px) and (max-width:1110px){
    
    .left{
        top: 530px !important;
    }
    .rigth{
        top:800px !important;
    }

    .map{

        svg{
            margin-left: none;
            width: 750px;
        }
        
    }
}

// @media(max-height:800px) and (max-width:1110px){
    
//     .rigth{
        
//         top: 120%;
//     }

//     .map{

//         svg{
//             margin-left: 50px;
//             width: 750px;
//         }
        
//     }
// }

// @media(max-height:510px) and (max-width:1110px){
//     .left{
//         top: 500px;
//     }
//     .rigth{
//         top:500px !important;
//     }
  
// }

@media (max-width:1080px){
    .left{
        right: 3% !important;
        top: 55% !important;
    }
    .map{

        svg{ 
            width: 800px;
        }
        
    }

    .rigth{
        top:88% !important;
        .card{
            width: 16rem !important;
        }
    }

}

@media(max-height:950px) and (max-width:1080px){

    .left{
        top:530px !important
    }
    .rigth{
        
        top: 700px !important
    }

    .map{

        svg{
            
            width: 750px;
        }
        
    }
}

@media(max-height:650px) and (max-width:1110px){
    .rigth{
        .card{
            width: 15rem !important;
        }
    }
}


@media(max-width:930px){
    .map{

        svg{
            margin-left: -110px;
            width: 750px;
        }
        
    }

    .rigth{
        bottom: -15%;
        .card{
            width: 16rem !important;
        }
    }
}

@media(max-height:950px) and (max-width:1080px){

    .rigth{
        
        top: 830px !important
    }
}

@media (max-width:850px){

    .left{
        right: 6% !important;
        top:55%;
        .card{
            width: 13rem !important;
            height: 12rem !important;
            .card-title {
                font-size: 25px;
            }
            .card-subtitle{
                font-size: 15px;
            }
            p{
                font-size: 12px;
            }
           }
    } 
    
    .map{

        svg{
            margin-left: -110px;
            width: 700px;
        }
        
    }
}

@media (max-height:960px) and (max-width:850px) {

    .left{
        top:530px;
    }

    .rigth{
        top: 830px !important;
    }
}

@media (max-width:800px) {
    .left{
       right: 10% !important;
       height: 10px !important; 
      
       .card{
        width: 11rem !important;
        height: 11rem !important;
        .card-title {
            font-size: 20px;
        }
        .card-subtitle{
            font-size: 13px;
        }
        p{
            font-size: 12px;
        }
       }
    }

    .map{

        svg{
            margin-left: none;
            width: 650px;
        }
        
    }

    .rigth{
        bottom: -7%;
        .card{
            width: 16rem !important;
        }
    }
    
    
}

@media (max-height:960px) and (max-width:800px) {

    .left{
        top:530px;
    }

    .rigth{
        top: 830px !important;
    }
}

@media (max-width:768px){

    .left{
        right: 7% !important;
        top:60% !important
    }
    .map{

        svg{
            margin-left: 0px;
            width: 600px;
        }
        
    }

    .rigth{
        top:80%;
        padding-bottom: 20px;
        padding-left: 10px;
        .card{
            width: 16rem !important;
        }
    }    
}

@media (max-height:960px) and (max-width:768px) {

    .left{
        top:580px !important;
    }

    .rigth{
        top: 810px !important;
    }
}

@media (max-width:670px){
    .map{

        svg{
            margin-left: 0px;
            width: 550px;
        }
        
    }

    .rigth{
        bottom: -4%;
        .card{
            width: 15rem !important;
        }
    } 
    
    .left{
        right: 6% !important;
        bottom: 38%;
    }
}

@media (max-width:615px ){

    .left{
        right: 5% !important;
        bottom: 39%;
        .card{
         width: 9rem !important;
         height: 10rem !important;
         .card-title {
             font-size: 15px;
         }
         .card-subtitle{
             font-size: 12px;
         }
         p{
             font-size: 11px;
         }
        }
     }
 

    .map{

        svg{
            margin-left: 0px;
            width: 470px;
        }
        
    }

    .rigth{
        
        bottom: -3.8%;
        
        .card{
            width: 16rem !important;
            .card-title {
                font-size: 20px;
            }
            .card-subtitle{
                font-size: 15px;
            }
            p{
                font-size: 12px;
            }
        }
    } 
}

@media(max-height:950px) and (max-width:615px){

   .left{

    top:550px !important;
   }
}

@media (max-width:530px){

    .left{
        right: 2% !important;
        bottom: 39%;
        .card{
         width: 9rem !important;
         height: 9rem !important;
         .card-title {
             font-size: 13px;
         }
         .card-subtitle{
             font-size: 10px;
         }
         p{
             font-size: 10px;
         }
        }
     }
 

    .map{

        svg{
            margin-left: 0px;
            width: 410px;
        }
        
    }
}

@media (max-width:467px){

    .left{
        right: 1% !important;
        bottom: 39%;
        .card{
         width: 8.5rem !important;
         height: 9rem !important;
         .card-title {
             font-size: 14px;
         }
         .card-subtitle{
             font-size: 10px;
         }
         p{
             font-size: 10px;
         }
        }
     }

    .map{

        svg{
            margin-top: 30px;
            margin-left: 0px;
            width: 350px;
        }
        
    }

    .rigth{
        
        bottom: -3.8%;
        
        .card{
            width: 10rem !important;
            height: 10rem;
            .card-title {
                font-size: 15px;
            }
            .card-subtitle{
                font-size: 9px;
            }
            p{
                font-size: 10px;
            }
        }
    } 

}

@media (max-width:420px){

    .left{
        right: 1% !important;
        bottom: 37%;
        .card{
         width: 8rem !important;
         height: 8rem !important;
         .card-title {
             font-size: 12px;
         }
         .card-subtitle{
             font-size: 9px;
         }
         p{
             font-size: 9px;
         }
        }
     }

    .map{

        svg{
            margin-top: 40px;
            margin-left: -10px;
            width: 300px;
        }
        
    }

    .rigth{
        
        bottom: -2%;
        
        .card{
            width: 10rem !important;
            height: 10rem;
            .card-title {
                font-size: 15px;
            }
            .card-subtitle{
                font-size: 9px;
            }
            p{
                font-size: 10px;
            }
        }
    } 
}

@media(max-height:950px) and (max-width:420px){

    .left{
        top:600px !important
    }
    .map{

        svg{
            margin-top: 60px !important;
            margin-left: -10px;
            width: 300px;
        }
        
    }
}


@media (max-height:1300px) and (max-width:850px) {

    .left{
        top:550px !important;
    }
    .rigth{
        top: 850px !important;

    }
}