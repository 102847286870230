.bodyCard {
  width: 60rem;
}

.contenedor {
  padding-top: 20px;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  font-size: 25px;
  text-align: justify;

  .centrado {
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-family: OpenSansBold;
  }

  .centradoIcono {
    width: 100%;
    font-size: 35px;
  }

  .formatoTexto {
    white-space: pre-wrap;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .linea {
    background-color: rgb(0, 0, 0);
    border-top: 5px dotted #0025f8;
  }
}

@media only screen and (max-width: 1180px) {
  .bodyCard {
    width: 50rem;
  }

  .contenedor {
    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    text-align: justify;
  }

  .centrado {
    font-size: 22px !important;
    font-family: OpenSansBold;
  }

  .formatoTexto {
    white-space: pre-wrap;
    text-align: justify;
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .bodyCard {
    width: 40rem;
  }

  .contenedor {
    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    text-align: justify;
  }

  .centrado {
    font-size: 20px !important;
    font-family: OpenSansBold;
  }

  .formatoTexto {
    white-space: pre-wrap;
    text-align: justify;
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 570px) {
  .bodyCard {
    width: 25rem;
  }

  .contenedor {
    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    text-align: justify;
  }

  .centrado {
    font-size: 17px !important;
    font-family: OpenSansBold;
  }

  .formatoTexto {
    white-space: pre-wrap;
    text-align: justify;
    font-size: 15px !important;
  }
}
