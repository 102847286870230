.registroComprador {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  
    label {
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      margin-bottom: 5px;
    }
  
    input {
      border-radius: 20px;
      border: 1px solid #ccc;
      padding: 10px;
      font-size: 16px;
  
      &:focus {
        outline: none;
        border-color: #007bff;
      }
    }
  }
  
  .linea-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
  }
  
  .linea {
    flex-grow: 1;
    height: 1px;
    background-color: black;
    margin: 0 5px;
    position: relative;
  }
  
  .linea:before {
    content: "";
    position: absolute;
    top: -4px;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
    transform: translateX(-50%);
  }
  .nav-box-blue {
    background-color: #233B7C;
    padding: 5px 10px;
    border-radius: 5px; 
    color: white; 
    background-color: #233B7C;
  }
  