@font-face {
  font-family: "OpenSansBold";
  font-size: 0.9em;
  src: local("OpenSansBold"), url("../../fonts/OpenSans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSansExtraBold";
  font-size: 0.9em;
  src: local("OpenSansBold"), url("../../fonts/OpenSans/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url("../../fonts/OpenSans/OpenSans-Regular.ttf");
}

body {
  font-family: "OpenSans";
  font-size: 0.85em;
}
