/*Diseño del Titulo*/
.titulo_menu {
  text-align: center;
  font-family: OpenSansBold;
  color: #172e68;
}

/*Diseño del Row*/
.container_row {
  justify-content: center;
  padding: 4em 10em;
}

/*Contenido del Contenedor = container*/
.container_Button {
  display: flex;
  justify-content: center;
  margin: 4em 10em;
}

.question_icon {
  color: rgb(14, 47, 139);
  margin-bottom: 1rem;
}

/*Propiedades de los Todos los Botones*/
.button {
  position: relative;
  width: 15em;
  height: 10em;
  border: 5px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  margin: 3em 3em;
}

/*Diseño del primer boton*/
.button.typeOne {
  color: #566473;
}

.button.typeOne:hover {
  background-color: rgb(197, 207, 218);
  color: rgb(15, 57, 172);
}

.button.typeOne.typeOne::after,
.button.typeOne.typeOne::before {
  content: "";
  display: block;
  position: absolute;
  width: 20%;
  height: 20%;
  border: 5px solid;
  transition: all 0.6s ease;
  border-radius: 2px;
}

.button.typeOne.typeOne::after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #566473;
  border-right-color: #566473;
}

.button.typeOne.typeOne::before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: #566473;
  border-left-color: #566473;
}

.button.typeOne.typeOne:hover:after,
.button.typeOne.typeOne:hover:before {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1140px) {
  .button {
    width: 12em;
    height: 20vh;
  }
  .container_row {
    justify-content: center;
    padding: 4em 5em;
  }
}

@media screen and (max-width: 960px) {
  .button {
    width: 15em;
    height: 20vh;
  }
  .container_row {
    justify-content: center;
    padding: 4em 3em;
  }
  .texto {
    font-size: 25px;
  }
}

@media screen and(max-width: 720px) {
  .button {
    width: 60vh;
    height: 20vh;
  }
  .container_row {
    justify-content: center;
    padding: 4em 7em;
  }
  .container_Button {
    display: flex;
    flex-wrap: wrap;
    margin: 4em 10em;
  }
}

@media screen and(max-width: 540px) {
  .button {
    width: 60vh;
    height: 20vh;
  }
  .container_row {
    justify-content: center;
    padding: 4em 4em;
  }
  .container_Button {
    display: flex;
    flex-wrap: wrap;
  }
}
